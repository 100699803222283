<template>
  <div class="loan-plan">
    <div class="plan-box">
      <div class="list">
        <div v-for="(item, index) in list" :key="item.id" class="item" :class="{ active: index == active }">
          <div class="title">
            <div class="span" @click.stop="goSelect(index)">{{ item.name }}</div>
            <div v-if="item.selected" class="status">
              <van-icon name="checked" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="list.length > 0" class="content">
        <div class="top">
          <div class="head">
            <div class="icon"><img :src="activePlanProduct.funderLogo" alt=""></div>
            <div class="name">{{ activePlanProduct.name }}</div>
            <div class="tap">{{ activePlanProduct.creditType }}</div>
            <div class="status"><van-checkbox v-model="activePlan.selected" :disabled="activePlan.disabled" checked-color="#ff7700" @click="changePlan(activePlan.disabled, activePlan.id)" /></div>
          </div>
          <div class="cont">
            <div class="item">
              <div class="title">额度范围</div>
              <div class="value">{{ activePlanProduct.loanFrom }}~{{ activePlanProduct.loanTo }}万</div>
            </div>
            <div class="item">
              <div class="title">月利率</div>
              <div class="value">{{ activePlanProduct.interestRateFrom }}~{{ activePlanProduct.interestRateTo }}%</div>
            </div>
            <div class="item">
              <div class="title">贷款期限</div>
              <div class="value">{{ activePlanProduct.creditLimitFrom }}~{{ activePlanProduct.creditLimitTo }}个月</div>
            </div>
          </div>
        </div>
        <div class="group">
          <div class="title"><span />还款方式</div>
          <div class="cont">
            <div class="item">
              <div class="tt">{{ paymentType }}</div>
            </div>
          </div>
        </div>
        <div class="group">
          <div class="title"><span />利率说明</div>
          <div class="cont">
            <div class="item">
              <div class="tt">贷款期限(月)</div>
              <div class="ct hot">{{ activePlanProduct.creditLimitFrom }}-{{ activePlanProduct.creditLimitTo }}</div>
            </div>
            <div class="item">
              <div class="tt">月利率(%)</div>
              <div class="ct normal">{{ activePlanProduct.interestRateFrom }}-{{ activePlanProduct.interestRateTo }}</div>
            </div>
          </div>
        </div>
        <div class="group">
          <div class="title"><span />须知</div>
          <div class="cont">
            <div class="item">
              <div class="tt">
                {{ activePlanProduct.serviceFeeRateExplain }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="orderPlanIds.length > 0" class="plan-confirm">
      <van-button
        block
        type="info"
        color="linear-gradient(90deg, #FE9D02 0%, #F78017 100%)"
        @click="goConfirm"
      >
        确认方案
      </van-button>
    </div>
  </div>
</template>
<script>
import { getOrderPlanList, confirmPlan } from '@/api/product'
export default {
  data() {
    return {
      list: [],
      orderId: '',
      active: 0,
      orderPlanIds: []
    }
  },
  computed: {
    activePlan() {
      return this.list[this.active]
    },
    activePlanProduct() {
      return this.activePlan.planProductList[0]
    },
    paymentType() {
      return this.activePlanProduct.paymentTypeList.join(',')
    }
  },
  mounted() {
    this.orderId = this.$route.query.orderId
    this.getOrderPlanList()
  },
  methods: {
    goSelect(index) {
      this.active = index
    },
    changePlan(isDisable, id) {
      if (isDisable) return
      const checked = this.orderPlanIds.some(item => item === id)
      if (checked) {
        this.orderPlanIds = this.orderPlanIds.filter(item => item !== id)
      } else {
        this.orderPlanIds.push(id)
      }
      console.log(this.orderPlanIds, '00000')
    },
    async getOrderPlanList() {
      const res = await getOrderPlanList({ orderId: this.orderId })
      const list = res.datas && res.datas.plans || []
      list.map(item => {
        item.selected = item.status === 2
        if (item.selected) { item.disabled = true } // 如果获取到的数据是已选的，则不可更改
      })
      this.list = list
      console.log(res)
    },
    async goConfirm() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      await confirmPlan({ orderId: this.orderId, orderPlanIds: this.orderPlanIds })
      loading.clear()
      this.$store.dispatch('user/setPlanStatus', true)
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.loan-plan {
  height: 100%;
  .plan-confirm {
    padding: 15px;
    text-align: center;
    color: #ff7700;
  }
  .plan-box {
    display: flex;
    height: 100%;
    .list {
      width: 85px;
      background: #f3f3f5;
      .item {
        display: flex;
        justify-content: center;
        padding: 15px 0;
        text-align: center;
        &.active {
          background: #fff;
        }
        .title {
          position: relative;
          font-size: 14px;
          .status::v-deep {
            position: absolute;
            right: -20px;
            top: -10px;
            color: #ff7700;
            .van-checkbox__icon {
              font-size: 14px;
            }
            .van-checkbox__icon--disabled.van-checkbox__icon--checked .van-icon {
              color: #fff;
            }
            .van-checkbox__icon--disabled .van-icon {
              background-color: #18c47c;
              border-color: #18c47c;
            }
          }
        }
      }
    }
    .content {
      flex: 1;
      padding: 16px 16px 80px 16px;
      overflow: auto;
      background: #fcfcfc;
      .top {
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 3px 6px rgba(0,0,0,0.06);
        .head {
          display: flex;
          align-items: center;
          .icon {
            img {
              width: 27px;
            }
          }
          .name {
            width: 100px;
            padding-left: 10px;
            color: #2E3135;
            font-weight: bold;
            line-height: 20px;
          }
          .tap {
            flex: none;
            padding: 4px 10px;
            margin-left: 10px;
            border-radius: 4px;
            font-size: 12px;
            color: #18C47C;
            background: #DCF6EB;
          }
          .status::v-deep {
            display: flex;
            flex: 1;
            justify-content: flex-end;
            .van-checkbox__icon--disabled.van-checkbox__icon--checked .van-icon {
              color: #fff;
            }
            .van-checkbox__icon--disabled .van-icon {
              background-color: #18c47c;
              border-color: #18c47c;
            }
          }
        }
        .cont {
          padding: 10px 16px;
          margin-top: 5px;
          border-radius: 4px;
          background: #FFF3E8;
          .item {
            display: flex;
            align-items: center;
            padding: 8px 0;
           .title {
              color: #999999;
              flex: 1;
            }
           .value {
              color: #2E3135;
              flex: 1;
              text-align: right;
            }
          }
        }
      }
      .group {
        .title {
          display: flex;
          padding: 16px 0 10px 0;
          span {
            width: 3px;
            height: 12px;
            margin-right: 10px;
            background: #FF7700;
          }
        }
        .cont {
          padding: 12px 29px;
          margin: 0 -16px;
          background: #fff;
          .item {
            display: flex;
            padding: 5px 0;
            .tt {
              flex: 1;
              color: #2E3135;
            }
            .ct {
              &.hot {
                color: #F86767;
              }
              &.normal {
                color: #2697FF;
              }
            }
          }
        }
      }
    }
  }
  .plan-confirm {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
  }
}
</style>
